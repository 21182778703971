import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { TrackCatalogExternalParams } from "@app/models/tracking/track-event-param-type";
import { CatalogViewServiceBase } from "@app/super/catalog-view-service-base";
import { isNullish } from '@commonHelpers/math-utils';
import { CatalogViewDto, SocialMediaType, TrackMailDto, TrackSocialMediaDto } from "@commonInterfaces/CatalogInfoModel";
import { TranslateModule } from "@commonNodeModules/@ngx-translate/core";
import { CatalogInfoViewerComponent as LibCatalogInfoViewerComponent } from '@commonViewers/cataloginfo-viewer/cataloginfo-viewer.component';
import { ViewType } from "@enums/view-type";
import { CatalogExternalViewType } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { ContactFormType } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { AnalyticsTrackingFacadeService } from "@services/analytics-tracking-facade.service";
import { TranslationService } from "@services/translation.service";
import { ViewTypeService } from "@services/view-type.service";
import { Subscription } from "rxjs";

@Component({
	selector: "app-cataloginfoviewer",
	templateUrl: "./cataloginfoviewer.component.html",
	styleUrls: ["./cataloginfoviewer.component.scss"],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LibCatalogInfoViewerComponent, TranslateModule]
})
export class CatalogInfoViewerComponent implements OnInit, OnDestroy {
	@Input() catalogKey: string;
	@Input() catalogViewService: CatalogViewServiceBase;

	@Output() contactFormOpen = new EventEmitter<any>();
	public model?: CatalogViewDto;

	public viewType = ViewType;
	private currentViewType$ = this.viewTypeService.currentViewType$
	private subscription: Subscription;
	public currentViewType: ViewType

	constructor(
		private viewTypeService: ViewTypeService,
		private translationService: TranslationService,
		private cdRef: ChangeDetectorRef,
		private trackingFacadeService: AnalyticsTrackingFacadeService
	) { }

	async ngOnInit() {

		const head = document.getElementsByTagName('head')[0];
		let element: HTMLLinkElement = document.querySelector(`link[rel='canonical']`) || null
		element = document.createElement('link') as HTMLLinkElement;
		head.appendChild(element);
		element.setAttribute('rel', 'canonical')
		element.setAttribute('href', `${window.location.origin}/catalog/${this.catalogKey}`)
		let additionalCategoriesTexts: string[] | undefined;
		const catalogInfo = await this.catalogViewService.getCatalogMeta(this.catalogKey);
		if (catalogInfo?.featureToggles?.simpleFeatureToggles?.LicenseAdditionalCategories) {
			additionalCategoriesTexts = catalogInfo.categoriesResourceKeys.map(m =>
				this.translationService.getByKey(m.categoryGroupResourceKey)
				+ " | "
				+ this.translationService.getByKey(m.categoryResourceKey)
			);
		}
		this.model = {
			key: this.catalogKey,
			additionalCategoriesTexts,
			...catalogInfo
		}

		this.subscription = this.currentViewType$.subscribe(currentViewType => {
			this.currentViewType = currentViewType
			this.cdRef.detectChanges();
		})

	}

	public trackWebEmailEvent(track: TrackMailDto) {
		this.trackingFacadeService.handleTrackEvent(new TrackCatalogExternalParams(this.catalogKey, track.viewType, track.link))
	}

	public trackSocialMediaEvent(track: TrackSocialMediaDto) {
		this.trackingFacadeService.handleTrackEvent(new TrackCatalogExternalParams(this.catalogKey, this.convertSocialMediaEvent(track.socialMediaType), track.link))
	}

	private convertSocialMediaEvent(type: SocialMediaType): CatalogExternalViewType {
		switch (type) {
			case SocialMediaType.Xing: return CatalogExternalViewType.Xing; break;
			case SocialMediaType.Facebook: return CatalogExternalViewType.Facebook; break;
			case SocialMediaType.Twitter: return CatalogExternalViewType.Twitter; break;
			case SocialMediaType.Instagram: return CatalogExternalViewType.Instagram; break;
			case SocialMediaType.LinkedIn: return CatalogExternalViewType.LinkedIn; break;
			case SocialMediaType.YouTube: return CatalogExternalViewType.YouTube; break;
			case SocialMediaType.Pinterest: return CatalogExternalViewType.Pinterest; break;
			default: return CatalogExternalViewType.Unknown;
		}
	}


	ngOnDestroy(): void {
		const head = document.getElementsByTagName('head')[0];
		const element: HTMLLinkElement = document.querySelector(`link[rel='canonical']`) || null
		if (!isNullish(element)) {
			head.removeChild(element);
		}
		this.subscription?.unsubscribe();
	}

	openContactForm() {
		this.contactFormOpen.emit({ contactFormType: ContactFormType.CatalogContact });
	}
}
