import { Injectable } from '@angular/core';
import { AlertDisplayType } from '@commonEnums/AlertDisplayType';
import { Observable, Subject } from 'rxjs';

export interface Alert<T> {
	positionType: T,
	displayType: AlertDisplayType,
	text: string
}

@Injectable({
	providedIn: "root"
})
export class AlertService<T> {
	private subject = new Subject<Alert<T>>();

	sendMessage(positionType: T, displayType: AlertDisplayType, text: string) {
		this.subject.next(
			{
				positionType,
				displayType,
				text
			}
		);
	}

	getMessage(): Observable<Alert<T>> {
		return this.subject.asObservable();
	}
}
