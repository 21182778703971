import { EventEmitter, Injectable } from "@angular/core";
import { TrackExportParams, TrackLoginRequestParams } from "@app/models/tracking/track-event-param-type";
import { AlertDisplayType } from "@commonEnums/AlertDisplayType";
import { isNullish } from "@commonHelpers/math-utils";
import { AlertService } from "@commonServices/alert.service";
import { ConfirmDialogService } from "@commonServices/confirm-dialog.service";
import { AlertPositionType } from "@enums/alertPositionType";
import { ExportSource, FormatId, LoginSource } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import {
	Metadata,
	TagType,
	ViewGroup,
	ViewGroupItemDto
} from "@interfaces/HttpClient/CatalogApiPublicModels";
import { AccountService } from "./account.service";
import { AnalyticsTrackingFacadeService } from "./analytics-tracking-facade.service";
import { CatalogDownloadService } from "./catalog-download.service";
import { TranslationService } from "./translation.service";
import { UserAuthorizationService } from "./user-authorization.service";
import { ViewTypeService } from "./view-type.service";

export interface MetaData {
	AttachmentName?: string;
	FileSize?: string;
	FileSizeFormat?: string;
	Duration?: string;
	Pages?: number;
	Description?: string;
	Tags?: TagType;
	Extension?: string;
	DownloadUrl?: string;
	FileName?: string;
}
@Injectable()
export class MetadataDownloadService {
	public element: any;
	private metadata: MetaData = {};
	public downloadAnimation = new EventEmitter<number>();
	private static watchlistExportAnimation = new EventEmitter<number>();
	private static downloadInQueue = 0;
	private static watchlistExportQueue = 0;

	constructor(
		private alertService: AlertService<AlertPositionType>,
		private translationService: TranslationService,
		private userAuthorizationService: UserAuthorizationService,
		private confirmDialogService: ConfirmDialogService,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
		private accountService: AccountService,
		private viewTypeService: ViewTypeService,
		private downloadService: CatalogDownloadService
	) { }

	public async downloadFile(attachment: ViewGroupItemDto, loginRequestSource: LoginSource, exportSource: ExportSource, catalogKey?: string, catalogItemGuid?: string, externalFile?: string) {
		if (attachment.isPreview) {
			this.alertService.sendMessage(AlertPositionType.ActionButtonTop, AlertDisplayType.Warning, this.translationService.getByKey("preview.notify-message"))
			return;
		}
		const isUserLogin = this.userAuthorizationService.isLoggedIn();
		if (attachment.isDownloadLoginRequired && !isUserLogin) {
			this.confirmDialogService.open({
				title: 'catalog-view.thirdpartyviewer.request-login.title',
				confirm: 'global.login-registration',
				description: 'catalog-view.thirdpartyviewer.request-login.description-download',
				reject: 'catalog-view.thirdpartyviewer.request-login.reject',
				descriptionVars: { group: this.getNamedGroup(attachment.viewGroup) }
			}, async () => { await this.login(loginRequestSource, catalogKey, catalogItemGuid, isNullish(attachment.id) ? null : attachment.id) }, async () => { });

		} else {

			this.trackingFacadeService.handleTrackEvent(new TrackExportParams(
				catalogKey,
				catalogItemGuid,
				isNullish(attachment.id) ? null : attachment.id,
				FormatId.Unknown,
				exportSource
			))
			if (isNullish(externalFile)) {
				this.alertService.sendMessage(this.viewTypeService.getPositionType(), AlertDisplayType.Success, this.translationService.getByKey("catalog-view.startDownload"))
			}

			attachment.metadataList.forEach(meta => {
				this.metadata[Metadata[meta.key]] =
					meta.tags.length > 0 ? meta.tags : meta.value;
			});

			const downloadUri = isNullish(externalFile) ?
				await this.downloadService.getAttachmentDownloadUri(attachment.id) :
				externalFile;
			if (!isNullish(downloadUri)) {
				this.download(downloadUri);
			}
		}

	}

	public downloadExport(downloadUri: string) {
		this.download(downloadUri);
	}

	private download(downloadUri: string) {
		const isInIframe = window.location !== window.parent.location
		if (isInIframe) {
			window.top.location.href = downloadUri
		} else {
			window.location.href = downloadUri
		}
	}

	public getNamedGroup(viewGroup: ViewGroup) {
		switch (viewGroup) {
			case ViewGroup.CadAndBim: return this.translationService.getByKey('catalog-view.thirdpartyviewer.request-login.download-type.cadbim');
			case ViewGroup.Documents: return this.translationService.getByKey('catalog-view.thirdpartyviewer.request-login.download-type.document');
			default: return this.translationService.getByKey('catalog-view.thirdpartyviewer.request-login.download-type.attachment');
		}
	}

	private async login(loginRequestSource: LoginSource, catalogKey: string, catalogItemGuid: string, attachmentId: number) {
		this.userAuthorizationService.clearData();
		this.trackingFacadeService.handleTrackEvent(new TrackLoginRequestParams(loginRequestSource, catalogKey, catalogItemGuid, attachmentId))
		await this.trackingFacadeService.commitAllTrackEvents();
		await this.accountService.login();
	}

	public get watchlistExportAnimation() {
		return MetadataDownloadService.watchlistExportAnimation
	}

	public addToQueue(isMailSend: boolean) {
		this.alertService.sendMessage(this.viewTypeService.getPositionType(), AlertDisplayType.Success, this.translationService.getByKey(`catalog-view.${isMailSend ? 'createDownloadLink' : 'startDownload'}`));
		MetadataDownloadService.downloadInQueue++;
		this.downloadAnimation.emit(MetadataDownloadService.downloadInQueue);
	}

	public removeFromQueue() {
		MetadataDownloadService.downloadInQueue--;
		this.downloadAnimation.emit(MetadataDownloadService.downloadInQueue);
	}

	public addToWatchlistExportQueue(isMailSend: boolean) {
		this.alertService.sendMessage(this.viewTypeService.getPositionType(), AlertDisplayType.Success, this.translationService.getByKey(`catalog-view.${isMailSend ? 'createDownloadLink' : 'startDownload'}`));
		MetadataDownloadService.watchlistExportQueue++;
		MetadataDownloadService.watchlistExportAnimation.emit(MetadataDownloadService.watchlistExportQueue);
	}

	public removeFromWatchlistExportQueue() {
		MetadataDownloadService.watchlistExportQueue--;
		MetadataDownloadService.watchlistExportAnimation.emit(MetadataDownloadService.watchlistExportQueue);
	}

	public hasWatchlistExport() {
		return MetadataDownloadService.watchlistExportQueue > 0;
	}
}
