import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CatalogViewServiceBase } from '@app/super/catalog-view-service-base';
import { RequestService } from "@commonServices/request.service";
import { CadenasDto, CatalogItemDetailsDto, CatalogItemFolderViewDto, CatalogItemMetaDto, Client as CatalogPublicAPI, CatalogViewCatalogInformationDto, CatalogViewContactListDto, CatalogViewForeignLanguageCatalogsDto, CatalogViewGroupDto, CatalogViewNewsListDto, CatalogViewNewsShortDto, CatalogViewRelatedCatalogsDto, CatalogViewTagDto, DgnbDto, ViewGroupItemDto } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { Subject, firstValueFrom, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CatalogViewService extends CatalogViewServiceBase {

	constructor(
		private catalogPublicAPI: CatalogPublicAPI,
		private requestService: RequestService
	) { super() }
	private nextViewerCall: Subject<void> = new Subject<void>();
	private nextMetaCall: Subject<void> = new Subject<void>();
	private static newsInit: { key: string; value: CatalogViewNewsShortDto[] };
	private static news: { key: string; value: CatalogViewNewsListDto };
	private static currentCatalogMeta: { key: string, value: CatalogViewGroupDto };
	private static currentCatalogInfo: { key: string; value: CatalogViewCatalogInformationDto };
	private static currentForeignLanguageCatalogs: { key: string; value: CatalogViewForeignLanguageCatalogsDto[] };
	private static currentRelatedCatalogs: { key: string; value: CatalogViewRelatedCatalogsDto[] };
	private static currentTags: { key: string; value: CatalogViewRelatedCatalogsDto[] };
	private static currentAttachment: { guid: string; value: CatalogItemDetailsDto };
	private static currentContact: { key: string; value: CatalogViewContactListDto };
	private static currentCatalogItemMeta: { guid: string; value: CatalogItemMetaDto };
	private static currentCadenasData: { url: string; value: CadenasDto };

	private static attachments: ViewGroupItemDto[];

	setAttachments(attachments: ViewGroupItemDto[]) {
		CatalogViewService.attachments = attachments;
	}

	getAttachments() {
		return CatalogViewService.attachments;
	}

	async getDgnbSustainabilityData(catalogItemGuid: string): Promise<DgnbDto> {
		try {
			return firstValueFrom(this.catalogPublicAPI.catalogView_GetDgnbSustainabilityInfo(catalogItemGuid))
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HttpStatusCode.NoContent, return: undefined }
			]);
		}
	}

	async getRelatedCatalogs(key: string): Promise<CatalogViewRelatedCatalogsDto[]> {
		try {
			if (CatalogViewService.currentRelatedCatalogs?.key === key) {
				return firstValueFrom(of(CatalogViewService.currentRelatedCatalogs?.value))
			}
			const result = await firstValueFrom(this.catalogPublicAPI
				.catalogView_GetRelatedCatalogsByCatalogKey(key));
			CatalogViewService.currentRelatedCatalogs = {
				key, value: result
			};
			return result;

		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getForeignLanguageCatalogs(key: string): Promise<CatalogViewForeignLanguageCatalogsDto[]> {
		try {
			if (CatalogViewService.currentForeignLanguageCatalogs?.key === key) {
				return firstValueFrom(of(CatalogViewService.currentForeignLanguageCatalogs?.value))
			}
			const result = await firstValueFrom(this.catalogPublicAPI
				.catalogView_GetForeignLanguageCatalogsByCatalogKey(key));
			CatalogViewService.currentForeignLanguageCatalogs = {
				key, value: result
			};
			return result;
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getAttachmentTags(key: string): Promise<CatalogViewTagDto[]> {
		try {
			if (CatalogViewService.currentTags?.key === key) {
				return firstValueFrom(of(CatalogViewService.currentTags?.value))
			}
			const result = await firstValueFrom(this.catalogPublicAPI
				.catalogView_GetAttachmentTagsByCatalogKey(key));
			CatalogViewService.currentTags = {
				key, value: result
			};
			return result;
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getCatalogInformation(
		key: string
	): Promise<CatalogViewCatalogInformationDto> {
		try {
			this.nextViewerCall.next();
			if (CatalogViewService.currentCatalogInfo?.key === key) {
				return firstValueFrom(of(CatalogViewService.currentCatalogInfo?.value))
			}

			const result = await firstValueFrom(this.catalogPublicAPI
				.catalogView_GetCatalogInformationByCatalogKey(key)
				.pipe(takeUntil(this.nextViewerCall)));
			CatalogViewService.currentCatalogInfo = {
				key, value: result
			};
			return result;

		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getNewsForCatalogView(key: string): Promise<CatalogViewNewsShortDto[]> {
		try {
			if (key === CatalogViewService.news?.key) {
				return firstValueFrom(of(CatalogViewService.newsInit?.value))
			}
			const result = await firstValueFrom(this.catalogPublicAPI
				.catalogView_GetNewsForCatalogView(key));
			CatalogViewService.newsInit = {
				key, value: result
			}
			return result;
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	// catalog content // position from catalog tree
	async getAttachmentsByCatalogItemGuid(
		guid: string
	): Promise<CatalogItemDetailsDto> {
		try {
			this.nextViewerCall.next();
			if (guid === CatalogViewService.currentAttachment?.guid) {
				return firstValueFrom(of(CatalogViewService.currentAttachment?.value))
			}
			// GetAttachments / Flache Liste
			const result = await firstValueFrom(this.catalogPublicAPI
				.catalogView_GetCatalogItemDetailsByCatalogItemGuid(guid)
				.pipe(takeUntil(this.nextViewerCall)));
			CatalogViewService.currentAttachment = {
				guid, value: result
			}
			return result;

		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	// catalog content // katalog info ... company info ... related catalogs
	async getCatalogMeta(key: string): Promise<CatalogViewGroupDto> {
		try {
			this.nextViewerCall.next();
			if (CatalogViewService.currentCatalogMeta?.key === key) {
				return firstValueFrom(of(CatalogViewService.currentCatalogMeta?.value))
			}
			const result = await firstValueFrom(this.catalogPublicAPI.catalogView_GetCatalogMetaByCatalogKey(key).pipe(takeUntil(this.nextViewerCall)));
			CatalogViewService.currentCatalogMeta = {
				key, value: result
			};
			return result;
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getFolderView(catalogGuids: string[]): Promise<CatalogItemFolderViewDto[]> {
		try {
			this.nextViewerCall.next();
			return await firstValueFrom(this.catalogPublicAPI.catalogView_GetFolderView(catalogGuids).pipe(takeUntil(this.nextViewerCall)));
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HttpStatusCode.NoContent, return: undefined }
			]);
		}
	}

	public callNextView() {
		this.nextViewerCall.next();
	}

	async getContactPersonsView(key: string): Promise<CatalogViewContactListDto> {
		try {
			this.nextViewerCall.next();
			if (CatalogViewService.currentContact?.key === key) {
				return firstValueFrom(of(CatalogViewService.currentContact?.value))
			}
			const result = await firstValueFrom(this.catalogPublicAPI.catalogView_GetCatalogContactsByCatalogKey(key).pipe(takeUntil(this.nextViewerCall)));
			CatalogViewService.currentContact = {
				key, value: result
			};
			return result;
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HttpStatusCode.NoContent, return: undefined }
			]);
		}
	}

	async getCatalogItemMeta(guid: string): Promise<CatalogItemMetaDto> {
		try {
			this.nextMetaCall.next();
			if (CatalogViewService.currentCatalogItemMeta?.guid === guid) {
				return firstValueFrom(of(CatalogViewService.currentCatalogItemMeta?.value))
			}
			const result = await firstValueFrom(this.catalogPublicAPI.catalogView_GetCatalogItemMetaDataByGuid(guid).pipe(takeUntil(this.nextMetaCall)));
			CatalogViewService.currentCatalogItemMeta = {
				guid, value: result
			};
			return result;
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HttpStatusCode.NoContent, return: undefined }
			]);
		}
	}

	async getCadenasData(cadenasUrl: string): Promise<CadenasDto> {
		try {
			this.nextMetaCall.next();
			if (CatalogViewService.currentCadenasData?.url === cadenasUrl) {
				return firstValueFrom(of(CatalogViewService.currentCadenasData?.value))
			}
			const result = await firstValueFrom(this.catalogPublicAPI.catalogView_GetCadenasDataByUrl(cadenasUrl).pipe(takeUntil(this.nextMetaCall)));
			CatalogViewService.currentCadenasData = {
				url: cadenasUrl, value: result
			};
			return result;
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HttpStatusCode.NoContent, return: undefined }
			]);
		}
	}

}
