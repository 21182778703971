<div class="h-100">
	@if (catalogInfo) {
		<div class="d-flex justify-content-between">
			<div class="w-100 me-3">
				@if (catalogInfo.catalogDescription) {
					<article class="mt-4 catalog-text">
						{{ catalogInfo.catalogDescription }}
					</article>
				}
				<dl class="mt-4 d-grid grid-counts align-items-center mb-2">
					@for (item of countValues; track item) {
						<dt class="line-height-medium fw-normal key-title">
							{{ item.translationKey | translate }}
						</dt>
						<dd class="line-height-medium d-block badge badge-pill badge-numbers min-content ms-auto mb-0">
							{{ item.count | numberFormat }}
						</dd>
					}
				</dl>
				<dl class="d-flex pb-2 pt-3">
					<dt class="text-small fw-normal update-text pe-1">
						{{ keypart + '.last-update' | translate }}
					</dt>
					<dd class="text-small">
						{{ catalogInfo.lastUpdate | date: ('global.date-format-short' | translate) }}
					</dd>
				</dl>
				@if (catalogInfo.additionalCategoriesTexts?.length) {
					<dl class="pb-2 mb-2">
						<dt class="fw-bold key-title">
							{{ keypart + '.categories-headline' | translate }}
						</dt>
						<dd>
							<ul class="ps-3">
								@for (category of catalogInfo.additionalCategoriesTexts; track category) {
									<li class="pt-2">
										{{ category }}
									</li>
								}
							</ul>
						</dd>
					</dl>
				}
			</div>
			@if (showCatalogContact) {
				@defer {
					<lib-catalog-contact
						class="w-100"
						[catalogInfo]="catalogInfo"
						(contactFormOpen)="openContactForm()"
						(trackMail)="trackMail.emit($event)"
						(trackSocialMedia)="trackSocialMedia.emit($event)">
					</lib-catalog-contact>
				}
			}
		</div>
	} @else {
		<div class="h-100 w-100 d-flex justify-content-center align-items-center">
			<lib-loading [isBig]="bigLoadingSize"></lib-loading>
		</div>
	}
</div>
