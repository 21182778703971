import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { isNullish } from '@commonHelpers/math-utils';
import { CatalogViewDto, CountValues, TrackMailDto, TrackSocialMediaDto } from "@commonInterfaces/CatalogInfoModel";
import { NumberFormatPipe } from "@commonPipes/numberFormat";
import { TranslateModule } from "@ngx-translate/core";
import { CatalogContactComponent } from "../../components/catalog-contact/catalog-contact.component";
import { LoadingComponent } from "../../components/loading/loading.component";

@Component({
	selector: "lib-cataloginfo-viewer",
	templateUrl: "./cataloginfo-viewer.component.html",
	styleUrls: ["./cataloginfo-viewer.component.scss"],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LoadingComponent, CatalogContactComponent, TranslateModule, NumberFormatPipe, DatePipe]
})
export class CatalogInfoViewerComponent implements OnInit, OnChanges {
	@Input() catalogInfo?: CatalogViewDto | null;
	@Input() showCatalogContact = true;
	@Input() keypart?: string;
	@Input() bigLoadingSize = false;
	@Output() contactFormOpen = new EventEmitter();
	@Output() trackMail = new EventEmitter<TrackMailDto>();
	@Output() trackSocialMedia = new EventEmitter<TrackSocialMediaDto>();
	public countValues?: CountValues[];

	constructor(
	) { }


	ngOnInit() {
		this.setCountValues();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.setCountValues();
	}

	private setCountValues() {
		if (isNullish(this.catalogInfo)) {
			return;
		}
		const countValues: CountValues[] = [];
		if (this.catalogInfo.countTexts > 0) {
			countValues.push({ count: this.catalogInfo.countTexts, translationKey: 'attachments.texts' })
		}
		if (this.catalogInfo.countImagesAndVideos > 0) {
			countValues.push({ count: this.catalogInfo.countImagesAndVideos, translationKey: 'attachments.ImageAndVideo' })
		}
		if (this.catalogInfo.countDocuments > 0) {
			countValues.push({ count: this.catalogInfo.countDocuments, translationKey: 'attachments.Documents' })
		}
		if (this.catalogInfo.countCadAndBim > 0) {
			countValues.push({ count: this.catalogInfo.countCadAndBim, translationKey: 'attachments.CadAndBim' })
		}
		if (this.catalogInfo.countLinks > 0) {
			countValues.push({ count: this.catalogInfo.countLinks, translationKey: 'attachments.Links' })
		}
		this.countValues = countValues;
	}


	openContactForm() {
		this.contactFormOpen.emit();
	}
}

