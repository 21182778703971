import { IEnvironment } from "@interfaces/iEnvironment";

export const environment: IEnvironment = {
	production: true,
	catalogApi: "https://catalog.qs.ade.adesystem.net",
	analyticsApi: "https://analytics.qs.ade.adesystem.net",
	protocolApi: "https://protocol.qs.ade.adesystem.net",
	importApi: "https://import.qs.ade.adesystem.net",
	exportApi: "https://export.qs.ade.adesystem.net",
	searchApi: "https://search.qs.ade.adesystem.net",
	catalogManagerUrl: "https://qs.catalogmanager.orcalab.de",
	webApi: "https://qs.ade.adesystem.net",
	consentApi: "https://consent.qs.ade.adesystem.net",
	blobUrl: "https://adedev0qs0sa.blob.core.windows.net",
	naturalLanguageProcessingApi: "https://nlp.qs.ade.adesystem.net",
	accountApi: "https://account.qs.ade.adesystem.net",
	ftpApi: "https://ftp.qs.ade.adesystem.net",
	helpdesk: "https://helpdesk-test.orca-software.com",
	catalogOfferRequestUrl: "https://info.ausschreiben.de/fuer-produkthersteller/angebot-paket-erweiterungen",
	analyticsOfferRequestUrl: "https://info.ausschreiben.de/fuer-produkthersteller/angebot-paket-erweiterungen",
	integrationOfferRequestUrl: "https://info.ausschreiben.de/fuer-produkthersteller/angebot-paket-erweiterungen",
	infoAdeUrl: "https://info.ausschreiben.de",
	infoletterUrl: "https://info.ausschreiben.de/wissen/infoletter-fuer-planer/?utm_source=orca&utm_medium=ade&utm_campaign=nlade",
	infoIntegrationUrl: "https://info.ausschreiben.de/fuer-produkthersteller/katalog-integration",
	infoAnalyticsUrl: "https://info.ausschreiben.de/fuer-produkthersteller/analytics/",
	aboutUsUrl: "https://info.ausschreiben.de/ueber-uns",
	aboutUsGaebUrl: "https://info.ausschreiben.de/ueber-uns/gaeb",
	contactUrl: "https://info.ausschreiben.de/ueber-uns/kontakt",
	mediaDataUrl: "https://info.ausschreiben.de/fuer-produkthersteller/mediadaten",
	manufacturerUrl: "https://info.ausschreiben.de/fuer-produkthersteller",
	objectConsultantUrl: "https://info.ausschreiben.de/fuer-objektberater",
	planerUrl: "https://info.ausschreiben.de/fuer-planer",
	softwareUrl: "https://info.ausschreiben.de/fuer-softwarehersteller",
	helpdeskFaqUrl: "https://helpdesk-test.orca-software.com/Solution/Produkthersteller/Content/intro-2_25399",
	newCustomerUrl: "https://info.ausschreiben.de/fuer-produkthersteller?utm_source=orca&utm_medium=ade&utm_campaign=PH-Akquise",
	supportRequestUrl: "https://helpdesk-test.orca-software.com/support/external/?product=Produkthersteller",
	alertInvalidTrackingValues: true,
	msal: {
		clientId: "ec47958e-5f1a-463c-abc3-1b63f93f588e",
		middlewareAppIdUriSuffix: "adeqsmiddleware",
		tenant: "orcaidentitydev",
		signInFlow: "B2C_1A_SignIn",
		signUpFlow: "",
		passwordResetFlow: "B2C_1A_PasswordReset",
		profileEditFlow: "B2C_1A_ProfileEdit",
		apiAccessScope: "user_impersonation"
	},
	googleTagManager: {
		id: "GTM-TJL4C84",
		gtmAuth: "Skps5jB_YP3_MSAUqgxHxg",
		gtmPreview: "env-20",
		gtmResourcePath: ""
	},
	ccm19Url: "https://consent.qs.ade.adesystem.net/ccm/app.js?apiKey=14176526217ccf59b9ecab96615d70f0711d28cc70fdf478&domain=9e1d037",
	helpdeskApiKey: "7383ff6173f9d26aa33094551c83d612",
	applicationInsightsSettings: {
		instrumentationKey: "fd857ccf-245e-4570-98f8-aa6dbe5d032d",
		enableAdaptiveSampling: false,
	},
	feedbackKey: "BD727055-04D7-47C3-AFEA-FE1699609634",
	needsAdditionalAuthenticationInformation: true,
	portalApi: "https://qs.ade.adesystem.net/"
}
