
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { fadeIn, fadeOut } from '@commonAnimations/animations';
import { AlertUiComponent } from '@commonComponents/alert-ui/alert-ui.component';
import { Alert, AlertService } from '@commonServices/alert.service';
import { AlertPositionType } from '@enums/alertPositionType';
import { Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	animations: [fadeIn, fadeOut],
	standalone: true,
	imports: [AlertUiComponent]
})
export class AlertComponent implements OnInit, OnDestroy, OnChanges {

	AlertPositionType = AlertPositionType

	@Input() positionType: AlertPositionType
	alertSubscription: Subscription
	alert: Alert<AlertPositionType>;
	public alertCssClass?: string;

	constructor(
		private alertService: AlertService<AlertPositionType>,
	) { }

	ngOnInit(): void {

		this.subscribeToAlertService()
	}

	ngOnChanges() {
		this.alertCssClass = this.getCssClassByPositionType();
	}

	subscribeToAlertService() {
		this.alertSubscription = this.alertService.getMessage()
			.pipe(
				filter(alert => alert?.positionType === this.positionType),
			)
			.subscribe(alert => {
				this.alert = alert;
				const alertTimer = timer(5000).subscribe(() => { this.alert = null; alertTimer.unsubscribe() });
			})
	}

	private getCssClassByPositionType() {
		switch (this.positionType) {
			case AlertPositionType.Global:
				return 'orca-alert-global'
			case AlertPositionType.ButtonCopyVideoLink:
				return 'orca-alert-tooltip'
			case AlertPositionType.ActionButtonTop:
				return 'orca-alert-actionbuttons-top'
			default:
				return 'w-100'
		}
	}

	ngOnDestroy() {
		this.alertSubscription.unsubscribe()
	}
}